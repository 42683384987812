@tailwind utilities;


@screen md {
  .interview-cover-art {
    max-width: 200%;
    margin-left: -50%;
    z-index: -10;
    position: relative;
  }
}
