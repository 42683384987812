/*
Button classes

BASE
btn

COLORS
btn-primary
btn-secondary
btn-accent
btn-info
btn-success
btn-warning
btn-error

VARIANT
btn-soft
btn-ghost
btn-link
btn-outline
glass

STATE
btn-active
btn-disabled
disabled
loading
no-animation

SIZES
btn-xl
btn-lg
btn-md
btn-sm
btn-xs

DISPLAY
btn-wide
btn-block
btn-circle
btn-square

*/
@tailwind utilities;
@tailwind components;
@layer components {
  .btn {
    @apply inline-flex items-center rounded border border-transparent font-normal text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-center justify-center gap-1 cursor-pointer;
  }

  /* Variants */
  .btn {
    &.btn-solid {
      @apply text-white;
    }
    &.btn-soft {
      @apply bg-gray-100 text-gray-700 hover:bg-gray-200;
    }
    &.btn-ghost {
      @apply shadow-none text-gray-500;
    }
    &.btn-outline {
      @apply shadow-none text-gray-500 border;
    }
  }

  /* Color */
  .btn {
    &.btn-primary {
      @apply focus:ring-primary-200;
    }
    &.btn-solid.btn-primary {
      @apply bg-primary hover:bg-primary-900;
    }
    &.btn-primary.btn-soft {
      @apply bg-primary-200 text-primary-700 hover:bg-primary-300;
    }
    &.btn-primary.btn-ghost {
      @apply hover:bg-primary-100 text-primary-700;
    }
    &.btn-primary.btn-outline {
      @apply border-primary-500 hover:border-primary-800 hover:text-primary-700 hover:bg-primary-200;
    }

    &.btn-secondary {
      @apply focus:ring-secondary-200;
    }
    &.btn-solid.btn-secondary {
      @apply bg-secondary-400 hover:bg-secondary-600;
    }
    &.btn-secondary.btn-soft {
      @apply bg-secondary-100 text-secondary-700 hover:bg-secondary-200;
    }
    &.btn-secondary.btn-ghost {
      @apply hover:bg-secondary-100 text-secondary-700;
    }
    &.btn-secondary.btn-outline {
      @apply text-secondary-500 border-secondary-300 hover:border-secondary-400 hover:text-secondary-700 hover:bg-secondary-50;
    }

    &.btn-success {
      @apply focus:ring-success-200;
    }
    &.btn-solid.btn-success {
      @apply bg-success hover:bg-success-700;
    }
    &.btn-success.btn-soft {
      @apply bg-success-100 text-success-700 hover:bg-success-200;
    }
    &.btn-success.btn-ghost {
      @apply hover:bg-success-100 text-success-700;
    }
    &.btn-success.btn-outline {
      @apply text-success-500 border-success-500 hover:border-success-600 hover:text-success-700 hover:bg-success-100;
    }

    &.btn-warning {
      @apply focus:ring-warning-200;
    }
    &.btn-solid.btn-warning {
      @apply bg-warning hover:bg-warning-700;
    }
    &.btn-warning.btn-soft {
      @apply bg-warning-100 text-warning-700 hover:bg-warning-200;
    }
    &.btn-warning.btn-ghost {
      @apply hover:bg-warning-100 text-warning-700;
    }
    &.btn-warning.btn-outline {
      @apply text-warning-500 border-warning-500 hover:border-warning-600 hover:text-warning-600 hover:bg-warning-100;
    }

    &.btn-error {
      @apply focus:ring-error-200;
    }
    &.btn-solid.btn-error {
      @apply bg-error hover:bg-error-700;
    }
    &.btn-error.btn-soft {
      @apply bg-error-100 text-error-700 hover:bg-error-200;
    }
    &.btn-error.btn-ghost {
      @apply hover:bg-error-100 text-error-700;
    }
    &.btn-error.btn-outline {
      @apply text-error-500 border-error-500 hover:border-error-600 hover:text-error-700 hover:bg-error-100;
    }

    &.btn-info {
      @apply focus:ring-info-200;
    }
    &.btn-solid.btn-info {
      @apply bg-info hover:bg-info-700;
    }
    &.btn-info.btn-soft {
      @apply bg-info-100 text-info-700 hover:bg-info-200;
    }
    &.btn-info.btn-ghost {
      @apply hover:bg-info-100 text-info-700;
    }
    &.btn-info.btn-outline {
      @apply text-info-500 border-info-500 hover:border-info-600 hover:text-info-700 hover:bg-info-100;
    }

    &.btn-accent {
      @apply focus:ring-accent-200;
    }
    &.btn-accent.btn-solid {
      @apply bg-accent-100 text-accent-700 hover:bg-accent-200;
    }
    &.btn-accent.btn-soft {
      @apply bg-accent-100 text-accent-700 hover:bg-accent-200;
    }
    &.btn-accent.btn-ghost {
      @apply hover:bg-accent-100 text-accent-700;
    }
    &.btn-accent.btn-outline {
      @apply text-accent-500 border-accent-500 hover:border-accent-500 hover:text-accent-700 hover:bg-accent-100;
    }
  }

  /* Sizes */
  .btn {
    &.btn-xs {
      @apply px-2.5 py-1 text-xs rounded;
    }
    &.btn-sm {
      @apply px-3 py-1.5 text-sm;
    }
    &,
    &.btn-md {
      @apply px-4 py-2;
    }
    &.btn-lg {
      @apply px-4 py-2.5 text-base;
    }
    &.btn-xl {
      @apply px-6 py-3 text-base;
    }
  }

  button,
  &.btn {
    &.disabled,
    &:disabled {
      @apply opacity-50 cursor-not-allowed;
    }
    &.loading {
      pointer-events: all;
      cursor: wait;

      &:hover {
        pointer-events: all;
      }
      &:before {
        height: 0.75em;
        width: 0.75em;
      }
    }
  }
}
