.badge {
  @apply inline-flex items-center gap-x-1.5 rounded px-2 py-1.5 text-xs font-medium text-gray-900 ring-1 ring-inset leading-tight ring-transparent;
}

/* Variants */
.badge {
  &.badge-solid {
    @apply text-white;
  }
  &.badge-soft {
    @apply bg-gray-100 text-gray-700 hover:bg-gray-200 border border-solid border-gray-300;
  }
  &.badge-ghost {
    @apply shadow-none text-gray-500;
  }
  &.badge-link {
    @apply cursor-pointer;
  }
}

/* Color */
.badge {
  &.badge-primary {
    @apply focus:ring-primary-200;
  }
  &.badge-solid.badge-primary {
    @apply bg-primary;
    &.badge-link {
      @apply hover:bg-primary-700;
    }
  }
  &.badge-primary.badge-soft {
    @apply bg-primary-100 text-primary-700 border-primary-300;
    &.badge-link {
      @apply hover:bg-primary-200;
    }
  }
  &.badge-primary.badge-ghost {
    @apply hover:bg-primary-100 hover:text-primary-700;
  }

  &.badge-secondary {
    @apply focus:ring-secondary-200;
  }
  &.badge-solid.badge-secondary {
    @apply bg-secondary;
    &.badge-link {
      @apply hover:bg-secondary-700;
    }
  }
  &.badge-secondary.badge-soft {
    @apply bg-secondary-100 text-secondary-700 border-secondary-300;
    &.badge-link {
      @apply hover:bg-secondary-200;
    }
  }
  &.badge-secondary.badge-ghost {
    @apply hover:bg-secondary-100 hover:text-secondary-700;
  }

  &.badge-success.badge-link {
    @apply focus:ring-success-200;
  }
  &.badge-solid.badge-success {
    @apply bg-success;
    &.badge-link {
      @apply hover:bg-success-700;
    }
  }
  &.badge-success.badge-soft {
    @apply bg-success-100 text-success-700 border-success-300;
    &.badge-link {
      @apply hover:bg-success-200;
    }
  }
  &.badge-success.badge-ghost {
    @apply hover:bg-success-100 hover:text-success-700;
  }

  &.badge-warning.badge-link {
    @apply focus:ring-warning-200;
  }
  &.badge-solid.badge-warning {
    @apply bg-warning;
    &.badge-link {
      @apply hover:bg-warning-700;
    }
  }
  &.badge-warning.badge-soft {
    @apply bg-warning-100 text-warning-700 border-warning-300;
    &.badge-link {
      @apply hover:bg-warning-200;
    }
  }
  &.badge-warning.badge-ghost {
    @apply hover:bg-warning-100 hover:text-warning-700;
  }

  &.badge-error {
    @apply focus:ring-error-200;
  }
  &.badge-solid.badge-error {
    @apply bg-error;
    &.badge-link {
      @apply hover:bg-error-700;
    }
  }
  &.badge-error.badge-soft {
    @apply bg-error-100 text-error-700 border-error-300;
    &.badge-link {
      @apply hover:bg-error-200;
    }
  }
  &.badge-error.badge-ghost {
    @apply hover:bg-error-100 hover:text-error-700;
  }

  &.badge-info {
    @apply focus:ring-info-200;
  }
  &.badge-solid.badge-info {
    @apply bg-info;
    &.badge-link {
      @apply hover:bg-info-700;
    }
  }
  &.badge-info.badge-soft {
    @apply bg-info-100 text-info-700 border-info-300;
    &.badge-link {
      @apply hover:bg-info-200;
    }
  }
  &.badge-info.badge-ghost {
    @apply hover:bg-info-100 hover:text-info-700;
  }

  &.badge-accent {
    @apply focus:ring-secondary-200;
  }
  &.badge-accent.badge-solid {
    @apply bg-secondary;
    &.badge-link {
      @apply hover:bg-secondary-700;
    }
  }
  &.badge-accent.badge-soft {
    @apply bg-secondary-100 text-secondary-700 border-secondary-300;
    &.badge-link {
      @apply hover:bg-secondary-200;
    }
  }
  &.badge-accent.badge-ghost {
    @apply hover:bg-gray-100 hover:text-secondary-700;
  }
}

/* Sizes */
.badge {
  &.badge-xs {
    @apply px-2.5 py-1 text-xs;
  }
  &.badge-sm {
    @apply px-3 py-1.5 text-sm;
  }
  &,
  &.badge-md {
    @apply px-4 py-1;
  }
  &.badge-lg {
    @apply px-4 py-2.5 text-base rounded-md;
  }
  &.badge-xl {
    @apply px-6 py-3 text-base rounded-lg;
  }
}

.badge {
  &.loading {
    pointer-events: all;
    cursor: wait;

    &:hover {
      pointer-events: all;
    }
    &:before {
      height: 0.75em;
      width: 0.75em;
    }
  }
}
