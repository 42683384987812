@import "./tailwind.css";
@import "./utils/reset.css";

@layer utilities {
  .delayed-fade-out {
    --fade-out-delay: 5s;

    animation: delayedFadeOut 1s ease-in var(--fade-out-delay) forwards;
    animation-fill-mode: forwards;
  }
  .long-delay {
    animation-delay: 10s;
  }

  .delayed-fade-in {
    --fade-in-delay: .25s;

    animation: delayedFadeOIn 1s ease-in var(--fade-in-delay) forwards;
    animation-fill-mode: forwards;
  }

  @keyframes delayedFadeOut {
    from {
      opacity: 1;
      visibility: visible;
    }
    to {
      opacity: 0;
      visibility: hidden;
    }
  }
  @-webkit-keyframes delayedFadeOut {
    from {
      opacity: 1;
      visibility: visible;
    }
    to {
      opacity: 0;
      visibility: hidden;
    }
  }

  @keyframes delayedFadeOIn {
    from {
      opacity: 0;
      visibility: hidden;
    }
    to {
      opacity: 1;
      visibility: visible;
    }
  }
  @-webkit-keyframes delayedFadeOIn {
    from {
      opacity: 0;
      visibility: hidden;
    }
    to {
      opacity: 1;
      visibility: visible;
    }
  }
}

@layer base {
  html {
    font-size: 14px;
  }

  .pixel-art {
    image-rendering: pixelated;
  }
}
