@tailwind components;
@layer components {
  .tooltip {
    @apply z-30 max-w-[200px] relative;

    .tooltip-content {
      @apply bg-gray-800 dark:bg-black text-white rounded py-3 px-4 inline-block;
    }
  }

  .tooltip-wrapper {
    @apply inline-flex;
  }

  .tooltip-arrow {
    @apply absolute text-gray-800 dark:text-black visible inline-block;
  }
}
