.botui_message_content {
  &.message_gameCredit {
    @apply w-full relative overflow-hidden pb-80;

    .clouds {
      @apply fade-in;

      img {
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: initial;
        z-index: 0;
      }

      img:nth-child(1) {
        animation: scroll 50s ease-in-out infinite alternate;
      }
      img:nth-child(2) {
        animation: scroll 40s ease-in-out infinite alternate-reverse;
      }
      img:nth-child(3) {
        animation: scroll 30s ease-in-out infinite alternate-reverse;
      }
    }
  }
}

@keyframes scroll {
  from { left: 0; }
  to { left: -100%; }
}
