@tailwind components;

@layer components {
  .app-header {
    @apply border-b border-dark-200 dark:border-dark-600 text-dark-700 dark:text-dark-300 bg-white dark:bg-dark-900;
  }

  .app-nav-link {
    @apply hover:text-dark-900 dark:hover:text-white text-center font-light hover:cursor-pointer;

    &.active {
      @apply text-dark-500 font-bold;
    }
  }
}
