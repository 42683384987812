#li-app {
  .profile-card {
    @apply !p-0 rounded-none border-none;
  }
  .profile-card-body {
    @apply border;
  }

  .profile-card-content {
    @apply px-3;
  }

  .profile-card-image-container {
    /* margin is profile icon @ 64px / 2 + (border width / 2) so things are centered */
    @apply text-center border-4 border-white inline-block mx-auto bg-white -mt-[34px];
  }

  .profile-card-avatar-placeholder {
    @apply h-[64px] w-[64px] bg-secondary-100;
  }
}

