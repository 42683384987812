/*
  Default theme for BotUI
*/

:root {
  --font-size: 14px;
  --botui-width: 400px;
  --text-color: #fff;
  --primary-color: #37BC9B;
  --secondary-color: #209175;
  --font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.botui_action {
  @apply flex justify-between items-center p-2 border-t border-secondary-200 bg-white dark:bg-dark dark:border-dark-700 w-full;

  textarea,
  input:not([type='file']),
  select {
    @apply flex-1;
    border: 0;
    margin: 0 2px;
    border-radius: 0;
    padding: 5px 7px;
    vertical-align: bottom;
  }

  &.action_castSpell,
  &.action_miniGame {
    justify-content: flex-end;
  }

  &.action_input {
    form {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.botui_button {
  border: 0;
  margin: 0 2px;
  line-height: 1;
  cursor: pointer;
  font-weight: 500;
  padding: 7px 15px;
  border-radius: 4px;
  vertical-align: bottom;
  box-shadow: 2px 3px 4px 0 rgba(0,0,0,.25);

  &:hover,
  &:focus {
    box-shadow: 1px 2px 3px 0 rgba(0,0,0,.25);
  }
}

.storyPages {
 @apply border border-b-0 pt-1 bg-secondary-100;
  width: calc(100% - 10px);
  border-top-left-radius: 5px;
  overflow: hidden;

  & .storyPagesContainer {
    @apply grid border-r border-secondary-200 gap-1 pb-1 bg-white;
    width: calc(100% - 5px);
  }
}

.botui_message_list {
  @apply p-3;
}

.botui_action_container {
  @apply flex-auto flex;

  &:has(.botui_action) {
    @apply sticky bottom-0 items-end;
  }
}

.botui_message_content {
  @apply self-start bg-white text-dark font-mono rounded inline-block p-3 max-w-[90%];

  &.human {
    @apply self-end border;
  }
  &:not(.human) {
    @apply border;
  }

  &.message_codexRecord,
  &.message_lootDrop,
  &.message_gameCredit,
  &.message_spellCast,
  &.message_spellCastResponse {
    @apply p-0;
  }

  iframe {
    border: 0;
    width: 100%;
  }
}

.botui_app_container {
  @apply min-h-[700px] flex flex-col will-change-transform;
  width: 100%;
  height: 100%;
  line-height: 1;

  @media (min-width: var(--botui-width) ){
    height: 500px;
    margin: 0 auto;
    width: var(--botui-width);
  }
}

.botui_container {
  @apply flex-1;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.botui_message {
  @apply flex;
  margin: 10px 0;
  min-height: 20px;

  &:after {
    display: block;
    content: "";
    clear: both;
  }
}
.botui_message:has(.human) {
  justify-content: flex-end;
}


.botui_message_container {
  width: 100%;
  height: 100%;
}

.botui_wait {
  @apply px-6 py-3;
  line-height: 1.3;
}

.message_links {
  a {
    color: var(--text-color);
    word-break: break-all;
    text-decoration: underline;

    & + a {
      margin-left: 7px;
    }
  }
}
/*
  Animation of loading dots
*/
.loading_dot {
  @apply bg-secondary-200;
  width: .5rem;
  height: .5rem;
  border-radius: .5rem;
  display: inline-block;

  &:nth-last-child(1) {
    margin-left: .3rem;
    animation: loading .6s .3s linear infinite;
  }
  &:nth-last-child(2) {
    margin-left: .3rem;
    animation: loading .6s .2s linear infinite;
  }
  &:nth-last-child(3) {
    animation: loading .6s .1s linear infinite;
  }
}

@keyframes loading {
  0% {
    transform: translate(0, 0);
    @apply bg-secondary-600;
  }

  25% {
    transform: translate(0, -3px);
  }
  50% {
    transform: translate(0, 0px);
    @apply bg-secondary-600;
  }
  75% {
    transform: translate(0, 3px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

.slide-fade-enter-done {
  transition: all .3s ease;
}

.slide-fade-enter,
.slide-fade-exit-done {
  opacity: 0;
  transform: translateX(-10px);
}
