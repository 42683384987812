@tailwind utilities;
@tailwind components;
@layer components {
  .post-title {
    @apply font-serif font-normal oldstyle-nums !leading-tight;

    em {
      @apply not-italic text-highlight;
    }
  }

  .post-excerpt {
    @apply prose text-gray-400 prose-sm line-clamp-5 m-0 p-0 !max-w-full first-of-type:prose-p:mt-0 last-of-type:prose-p:mb-0;
  }
}
